@use 'src/theme/_variables.scss';
.Message {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 80px;
  padding: 5px;
  cursor: pointer;
}
.message-date {
  position: sticky;
  top: 0;
  height: 1rem;
  color: variables.$reposit-light-blue;
  font-size: 0.8rem;
}
.message-card {
  display: flex;
  flex-direction: row;
  height: calc(100% - 1rem);

  img {
    height: calc(100%);
    margin-right: 5px;
    aspect-ratio: 1 / 1;
  }
}

.message-content {
  display: flex;
  flex-direction: column;
  height: calc(100%);
  h6 {
    margin-bottom: 0;
    font-size: 1rem;
  }
  .message-body {
    display: -webkit-box;
    overflow: hidden;
    color: variables.$text-secondary;
    font-size: 0.7rem;
    text-overflow: ellipsis;
    word-wrap: break-word;
    -webkit-line-clamp: 2; // max nb lines to show
    -webkit-box-orient: vertical;
  }
}
.message-modal {
  display: flex;
  flex-direction: column;
  &-body {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 3.5rem;
      aspect-ratio: 1 / 1;
    }
  }
  &-time {
    color: variables.$text-secondary;
    font-size: 0.8rem;
  }
}
