@import '~react-calendar/dist/Calendar.css';
.History {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.react-calendar {
  width: 95%;
  max-width: 100%;
  color: #222;
  font-family: Arial, Helvetica, sans-serif;
  //   line-height: 1.125em;
  background-color: #fff;
  border-width: 0;
  border-radius: 8px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}
.react-calendar__navigation button {
  min-width: 44px;
  margin-top: 8px;
  color: #6f48eb;
  font-size: 16px;
  background: none;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #f8f8fa;
}
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}
abbr[title] {
  text-decoration: none;
}
/* .react-calendar__month-view__days__day--weekend {
      color: #d10000;
     } */
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  color: #6f48eb;
  background: #f8f8fa;
  border-radius: 6px;
}
.react-calendar__tile--now {
  color: #6f48eb;
  font-weight: bold;
  background: #6f48eb33;
  border-radius: 6px;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  color: #6f48eb;
  font-weight: bold;
  background: #6f48eb33;
  border-radius: 6px;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #f8f8fa;
}
.react-calendar__tile--active {
  color: white;
  font-weight: bold;
  background: #6f48eb;
  border-radius: 6px;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  color: white;
  background: #6f48eb;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #f8f8fa;
}
.react-calendar__tile--range {
  color: #6f48eb;
  background: #f8f8fa;
  border-radius: 0;
}
.react-calendar__tile--rangeStart {
  color: white;
  background: #6f48eb;
  border-top-left-radius: 6px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 6px;
}
.react-calendar__tile--rangeEnd {
  color: white;
  background: #6f48eb;
  border-top-left-radius: 0;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 0;
}

.react-calendar__navigation__label {
  pointer-events: none;
}

.react-calendar__navigation__prev2-button {
  display: none;
}
.react-calendar__navigation__next2-button {
  display: none;
}
