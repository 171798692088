@use 'src/theme/_variables.scss';
.Messages {
  align-items: center;
  width: 100%;
  height: 100%;
  .spinner {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.messages-list {
  overflow: visible;
}
.message-refresh-icon {
  position: fixed;
  top: 1rem;
  left: 320px;
  width: 2rem;
  height: 2rem;
  aspect-ratio: 1/1;
}

.message-refresh-icon-loading {
  position: fixed;
  top: 1rem;
  left: 320px;
  width: 2rem;
  height: 2rem;
  -webkit-animation: spin 1s linear infinite;
  -moz-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  aspect-ratio: 1/1;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
