@import "src/theme/_variables.scss";
@import "~bootstrap/scss/bootstrap";
@import "~react-bootstrap-sidebar-menu/dist/sidebar-menu.scss";

$sidebar-menu-width-expanded: 270px !default;
$sidebar-menu-width-collapsed: 80px !default;
.sidebar-menu {
  @extend .navbar;
  align-items: flex-start;
  max-width: $sidebar-menu-width-expanded;

  min-width: $sidebar-menu-width-collapsed;
}
.sidebar-menu-collapse.show {
  width: $sidebar-menu-width-expanded;
}
@media only screen and (max-width: 600px) {
  .sidebar-menu-header {
    height: 3rem
  }

  .sidebar-menu {
    @extend .navbar;
    align-items: flex-start;
    // max-width: $sidebar-menu-width-expanded;

    min-width: 100vw;
  }
  .sidebar-menu-collapse:not(.show) {
    width: $sidebar-menu-width-collapsed;
    display: block !important ;

    .sidebar-menu-text,
    .sidebar-menu-brand {
      display: none;
    }

    .sidebar-menu-header {
      justify-content: space-between !important;
    }
    .sidebar-menu-body {
      display: none !important;
    }

    .sidebar-menu-nav-title {
      display: none;
    }
  }
}

.sidebar-menu-body {
  padding: 0.5rem;
  overflow-y: auto;
  display: block !important;
}

.sidebar-menu-collapse.show {
  width: $sidebar-menu-width-expanded;
}

.sidebar-menu-collapse.collapse {
  display: block !important;
}

.sidebar-menu-collapse:not(.show) {
  width: $sidebar-menu-width-collapsed;
  display: block !important;

  .sidebar-menu-text,
  .sidebar-menu-brand {
    display: none;
  }

  .sidebar-menu-header {
    justify-content: flex-end;
  }

  .sidebar-menu-nav-title {
    display: none;
  }
}

.sidebar-menu-collapse.collapsing.width {
  height: auto;
  transition: width 0.35s;
}

.sidebar-menu-expand {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($next, $grid-breakpoints);

    &#{$infix} {
      @include media-breakpoint-down($next) {
        .sidebar-menu-collapse.show {
          width: $sidebar-menu-width-collapsed;
        }
        .sidebar-menu-body {
          display: none !important;
        }

        .sidebar-menu-header,
        .sidebar-menu-nav-title {
          display: none;
        }
      }
    }
  }
}

#detail {
  display: flex;
  width: 100%;
  min-height: 100vh;
  padding: 1rem 1rem 1rem 1rem;
}
.layout-container {
  display: "flex" !important;
  width: "100%" !important;
  height: "100%" !important;
}
@media only screen and (max-width: 600px) {
  .layout-container {
    display: "flex";
    width: "100%";
    height: "100%";
    flex-direction: column;
  }
}

.sidebar-logo {
  max-width: 200px;
  width: calc(100% - 3.5em);
  padding: 1rem 3.5rem 1rem 2rem;
}

.sidebar-menu-light .sidebar-menu-nav .sidebar-menu-nav-link.active {
  overflow: hidden;
  background-color: $reposit-light-blue;
  border-radius: 6px;
}
.logout {
  position: fixed;
  bottom: 0;
}
@media only screen and (max-width: 600px) {
  .logout {
    position: relative;
  }
}
.logout-modal {
  min-height: 16rem !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    color: $gray-600;
    margin-top: 2rem;
  }
}
.offcanvas {
  width: "350px";
}

@media only screen and (max-width: 600px) {
  .offcanvas {
    width: 100vw;
    min-width: 100vw;
  }
}
