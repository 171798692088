@use 'src/theme/_variables.scss';
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  min-width: 100vw;
  min-height: 100vh;
  background: linear-gradient(45deg, #7bd4eb 0%, #60bedf 75%, #6dc9e5 100%);
}

.password_form_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 23rem;
  margin-top: -5rem;
  padding: 4rem;
  font-size: 1rem;
  background-color: #fff;
  border-radius: 2rem;
}

.reposit_logo {
  width: calc(100% - 2rem);
  max-width: 300px;
  padding: 1rem 2rem 1rem 2rem;
}

.password_reset_text {
  margin-bottom: 1rem;
  color: white;
}

.text_muted {
  margin-top: 1rem;
  cursor: pointer;
}
