@use 'src/theme/_variables.scss';
.Login {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(45deg, #7bd4eb 0%, #60bedf 75%, #6dc9e5 100%);
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px;
  column-gap: 10px;
  background-color: white;
  border-radius: 20px;
}
.password-reset {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 20rem;
  font-size: 1rem;
}
.password-reset-text {
  margin-bottom: 1rem;
  color: white;
}

.text-muted {
  margin-top: 1rem;
  cursor: pointer;
}
