$theme: 'cosmo';

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #868e96;
$gray-700: #495057;
$gray-800: #373a3c;
$gray-900: #212529;
$black: #000;

// $blue: #2780e3  ;
$blue: rgb(255, 116, 0);
$indigo: #6610f2;
$purple: #613d7c;
$pink: #e83e8c;
$red: #ff0039;
$orange: #f0ad4e;
$yellow: #ff7518;
$green: #3fb618;
$teal: #20c997;
$cyan: #9954bb;

$min-contrast-ratio: 2.6;

// Body

$body-color: $gray-800;

// Fonts

// stylelint-disable-next-line value-keyword-case
$font-family-sans-serif: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
  'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
$headings-font-weight: 400;

// Navbar

$navbar-dark-hover-color: rgba($white, 1);
$navbar-light-hover-color: rgba($black, 0.9);

// Alerts

$alert-border-width: 0;

$reposit-blue: #4698cb;
$primary: $reposit-blue;
$secondary: $gray-800;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-800;

$progress-height: 0.5rem;

$reposit-light-blue: #3ca9d7;
$login-background: #60bedf;
$text-secondary: #868e96;

$activity-meter: rgb(70, 152, 203);
$activity-house: #51c76d;
$activity-energy: #57c4c7;
$activity-solar: #fe9e43;
$activity-battery: #57c4c7;

@import '~bootstrap/scss/bootstrap';

:export {
  primary: $primary;
  activity-meter: $activity-meter;
  activity-house: $activity-house;
  activity-energy: $activity-energy;
  activity-solar: $activity-solar;
  activity-battery: $activity-battery;
}
