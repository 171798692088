@import 'src/theme/variables';
.graph-widget {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 160px;
  max-height: 350px;
  aspect-ratio: 16/9;
}
.graph-widget .stats-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 4rem;
  padding: 1.8em 1.4em;
}

.graph-widget .stats-container .stat {
  box-sizing: border-box;
  width: 50%;
}

.graph-widget .stats-container .stat:first-child {
  margin-right: 1rem;
}

.graph-widget .stats-container .value {
  font-weight: 200;
  font-size: 2.25rem;
  line-height: 2.6rem;
}

.graph-widget .stats-container .label {
  font-weight: 400;
  font-size: 0.8rem;
  letter-spacing: 0.03em;
}

.graph-widget .graph-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: visible;
}

.graph-widget .stats-container .stat {
  width: initial;
}

.graph-widget .stats-container .value {
  width: 100%;
  font-weight: 300;
  font-size: 7rem;
  line-height: 8rem;
  text-align: center;
}

.graph-widget .stats-container .label {
  width: 100%;
  font-weight: 400;
  font-size: 2.6rem;
  text-align: center;
}

.graph-tooltip {
  position: absolute;
  z-index: 2147483647;
  min-width: 60px;
  padding: 0.1rem 0.5rem 0.2rem 0.5rem;
  background-color: white;
  border: 2px solid $gray-400;
  border-radius: 4px;
}
.graph-tooltip-line {
  position: absolute;
  z-index: 2147483647;
  display: flex;
  flex-direction: column;
  width: 2px;
  height: 100%;
  overflow: hidden;
  background-color: white;
  -webkit-box-shadow: 0px -1px 10px 2px $gray-600;
  -moz-box-shadow: 0px 0px 10px 2px $gray-600;
  box-shadow: 0px 0px 10px 2px $gray-600;
}
.graph-tooltip-circle {
  position: absolute;
  z-index: 2147483647;
  // display: block;
  width: 10px;
  height: 10px;
  overflow: hidden;
  border-color: white;
  border-style: solid;
  border-width: 2.4px;
  border-radius: 5px;
}
.graph-tooltip-data {
  font-size: 1.2rem;
  white-space: nowrap;
}
.graph-tooltip-tag {
  font-size: 0.8rem;
}
.graph-tooltip-time {
  color: $gray-700;
  font-size: 0.8rem;
}

.flyout-line {
  -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.2));
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.2));
}
.graph-tariff-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 10px;
  min-height: 10px;
  margin-top: 5px;
  border-radius: 10px;
  .graph-tariff {
    display: none;
    font-size: 0.8rem;
  }

  &:hover {
    height: 30px;
    min-height: 30px;
    border-radius: 15px;
    .graph-tariff {
      display: block;
      color: black;
    }
  }
}

.graph-tariff-wrapper:hover + graph-tariff {
  display: block;
  color: black;
  opacity: 1;
}
