@import 'src/theme/variables';
.activity-graph-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(50% - 10px);
  min-width: 150px;
}

.stats-container {
  display: flex;
  align-items: center;

  width: 100%;
  height: 4rem;

  .stat {
    flex: 1;
    justify-content: start;
    width: 100%;
    margin: 0;
    padding: 0;
  }
  .value {
    display: inline-block;
    flex-direction: row;
    align-items: center;
    height: 1.7rem !important;
    margin-left: 0;
    font-weight: 200;
    font-size: 1.5rem !important;

    &-symbol {
      display: inline-block;
      margin-left: 0.2rem;
      padding: 0.1rem 0.1rem 0.1rem 0.1rem !important;
      color: $secondary;
      font-weight: 400;
      font-size: 0.5rem !important;
      line-height: 0.5rem !important;
      vertical-align: middle;
      border: 1px solid $green-600;
      border-radius: 3px;
    }
  }
  .label {
    font-weight: 400;
    font-size: 0.8rem;
    letter-spacing: 0.03em;
  }
}

.meter {
  color: $activity-meter !important;
}

.house {
  color: $activity-house !important;
}
.solar {
  color: $activity-solar !important;
}
.battery {
  color: $activity-battery !important;
}
